import request from './request.js';
import public_request from './public_request';
//获取超时客户列表
export function client({ clientId }) {
    return request({
        url: '/client-management/client',
        method: 'get',
        params: public_request({
            clientId,
        }),
    });
}
// 获得所有客户
export function allClients(data) {
    return request({
        url: '/client-management/my/clients-all',
        method: 'get',
        params: public_request(data),
    });
}

// //获取客户列表
// export function clients({ qualityType, fundNeedsScopeType, followStage, getCategory, startTime, endTime, pageNo, pageSize }) {
//     return request({
//         url: '/client-management/my/clients',
//         method: 'get',
//         params: public_request({
//             qualityType, fundNeedsScopeType, followStage, getCategory, startTime, endTime, pageNo, pageSize
//         })
//     })
// }
//修改客户为重要客户
export function client_important({ clientId }) {
    return request({
        url: '/client-management/my/client-important',
        method: 'PUT',
        data: public_request({
            clientId,
        }),
    });
}
//修改客户为公海客户
export function client_public({ clientIds }) {
    return request({
        url: '/client-management/my/client-public',
        method: 'PUT',
        data: public_request({
            clientIds,
        }),
    });
}
//修改客户为普通客户
export function client_ordinary({ clientId }) {
    return request({
        url: '/client-management/my/client-ordinary',
        method: 'PUT',
        data: public_request({
            clientId,
        }),
    });
}
//获取客户跟进记录
export function client_followed_log({ clientId }) {
    return request({
        url: '/client-management/my/client-followed-logs',
        method: 'get',
        params: public_request({
            clientId,
        }),
    });
}
//获取即将超时列表
export function clients_expire(data) {
    return request({
        url: '/client-management/my/clients-expire',
        method: 'get',
        params: public_request(data),
    });
}
//获取重要客户列表
export function clients_important(data) {
    return request({
        url: '/client-management/my/clients-important',
        method: 'get',
        params: public_request(data),
    });
}
//保存客户跟进记录
export function client_followed_log1(data) {
    return request({
        url: '/client-management/my/client-followed-log',
        method: 'post',
        data: public_request(data),
    });
}
//新增客户
export function add_client(data) {
    return request({
        url: '/client-management/my/client',
        method: 'post',
        data: public_request(data),
    });
}
//转移我的客户
export function client_owner({ clientIds, inStaffId }) {
    return request({
        url: '/client-management/my/client-owner',
        method: 'PUT',
        data: public_request({
            clientIds,
            inStaffId,
        }),
    });
}
//客户信息是否存在未跟进
export function client_clue_unfollowed({ clientId }) {
    return request({
        url: '/client-management/client-clue-unfollowed',
        method: 'get',
        params: public_request({
            clientId,
        }),
    });
}
// 添加微信
export function addClientWx({ mobileNumber, message, clientId, clientName }) {
    return request({
        url: '/common/client-wechat-add-request',
        method: 'POST',
        data: public_request({
            mobileNumber,
            message,
            clientId,
            clientName,
        }),
    });
}
// 创建我的客户轮呼列表
export function createMyClientCallListApi(list) {
    return request({
        url: '/client-management/my/batch-follow-list',
        method: 'POST',
        data: public_request({
            list,
        }),
    });
}

// 获取我的客户轮呼列表
export function getMyClientCallListApi() {
    return request({
        url: '/client-management/my/batch-follow-list',
        method: 'GET',
        params: public_request(),
    });
}

// 获取公海客户随机轮呼列表
export function getHighSeasClientStochasticCallListApi(size) {
    return request({
        url: '/client-management/public/batch-follow-random-list',
        method: 'get',
        params: public_request({
            size,
        }),
    });
}
// 获取我的轮呼客户信息
export function getMyClientInfo(clientId) {
    return request({
        url: '/client-management/my/batch-follow-client',
        method: 'GET',
        params: public_request({
            clientId,
        }),
    });
}
// 获取公海轮呼客户信息
export function getMyHighSeasClientInfo(clientId) {
    return request({
        url: '/client-management/public/batch-follow-client',
        method: 'GET',
        params: public_request({
            clientId,
        }),
    });
}
